import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { List, Row, InfoIcon } from './components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "terminology"
    }}>{`Terminology`}</h1>
    <p>{`In order to effectively use the SmartVault REST API, you should be familiar with the following concepts`}</p>
    <br />
    <List values={[{
      title: 'Account',
      value: 'All data is stored inside a SmartVault account. An account consists of billing information, access control, and a series of invited users.\n\rDevelopers will have a developer account, but they will typically be interacting with a customer’s account.'
    }, {
      title: 'Client ID',
      value: 'A client ID represents a specific use of the SmartVault REST API.\n\rDevelopers will sign up for a developer’s account and for each unique application they develop (say, an iPhone application and a web based integration), they will have one client ID. SmartVault customers will grant specific client IDs the ability to impersonate users within an account context.'
    }, {
      title: 'User ID',
      value: 'A user ID represents a unique user in the SmartVault system. User IDs are granted specific access within one or more accounts. A User ID typically authenticates via password.\n\rHowever, using the SmartVault REST API, developers will use OAuth 2.0 to impersonate User IDs without requiring a password.'
    }, {
      title: 'Entities',
      value: 'At SmartVault, elements like clients engagements, employees, etc. are conceived as entities. You can find more info about entities in its own menu section.'
    }, {
      title: 'Application',
      value: 'An application represents an application that stores documents within SmartVault by associating records between the native application and SmartVault.\n\rAn example of an application is QuickBooks or a developer’s CRM solution. Client IDs and applications are easily confused concepts.\r\nCheck the example below for clarity.'
    }]} mdxType="List" />
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Application example
- Let’s assume as a developer you want to add document management support to your CRM solution.
  You have:
    1. A web based interface.
    2. An iPhone application.
    3. An Android application.

- You would sign up for one developer's account, create one client ID for the CRM application, and three client IDs to represent the three different applications used to access the CRM application.
`}</code></pre>
    <br />
    <br />
    <br />
    <hr />
    <br />
    <h2 {...{
      "id": "account-types"
    }}>{`Account types`}</h2>
    <p>{`Regarding the usage of the API, the SmartVault account type that you are using affects some endpoints.`}<br />{`
We can differentiate two main account types:`}</p>
    <InfoIcon mdxType="InfoIcon" />
Structured plan
    <br />
    <InfoIcon mdxType="InfoIcon" />
Non structured plan.
    <br />
    <br />
    <p>{`Regardless of account type, all of them have files, folders, vaults and apps, as well as user accounts present in their structure and can be managed and modified.`}<br /></p>
    <p>{`However, structured accounts have entities, templates and email templates definitions, exclusives for that account type.`}<br /><br />{`
For more information on how to retrieve the account type you are using check `}<a parentName="p" {...{
        "href": "/api#retrieving-plan-type"
      }}>{`here`}</a></p>
    <br />
    <br />
    <br />
    <hr />
    <br />
    <h2 {...{
      "id": "node-response"
    }}>{`Node response`}</h2>
    <p>{`The `}<strong parentName="p">{`NodeResponse`}</strong>{` is the workhorse of the SmartVault REST API. Understanding the structure of this response is key to working with the SmartVault REST API.`}<br />{`
The structure is made up of the following elements (some may vary depending of the API element being browsed; files, folders, entities, etc.):`}</p>
    <br />
    <Row mdxType="Row">
    <div style={{
        "background": "rgba(0, 0, 0, 0.25)",
        "padding": "2rem",
        "borderRadius": "1rem"
      }}>
        <List values={[{
          title: 'name',
          value: 'The name of the element.'
        }, {
          title: 'createdOn',
          value: 'Date when the node was created.'
        }, {
          title: 'modifiedOn',
          value: 'Last date the node was modified.'
        }, {
          title: 'uri',
          value: 'The uri of the element. The path used for the request that outputs this node\'s response.'
        }, {
          title: 'dav_uri',
          value: 'The dav uri of the element, this is used to request the element from its strict path rather than its logical path.'
        }, {
          title: 'download_uri',
          value: 'The download uri of the element. Only present if the API element being browsed is a file. This uri can be used to download the actual contents of the file.'
        }, {
          title: 'your_permissions',
          value: 'The permissions the current authenticated user has to this element.'
        }]} mdxType="List" />
    </div>
      <div>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Example of a node response:

{
    "error": {
        "success": true
    },
    "message": {
        "name": "Entities",
        "createdOn": "2020-06-30T06:40:35-05:00",
        "modifiedOn": "2020-06-30T06:40:35-05:00",
        "uri": "/nodes/entity",
        "your_permissions": 64
    }
}
`}</code></pre>
      </div>
    </Row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      